<template>
  <div class="container mx-auto max-w-5xl">
    <div class="app">
      <slot name="before" />
      <main class="app-main min-h-screen flex-shrink-0 pt-[40px] pb-[40px]">
        <slot />
      </main>
      <slot name="after" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.app {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';

  height: 100vh;
}

.header {
  grid-area: header;
}

main {
  grid-area: main;
  overflow: auto;
}
</style>
